<template>
  <div>
    <loading :active.sync="isLoading"
             :can-cancel="false"
             :is-full-page="fullPage"></loading>
    <div class="row justify-content-md-center">
      <div class="col-12">
        <form-wizard
          title=""
          subtitle=""
          color="#918158"
          nextButtonText='Avançar'
        >
          <tab-content :title="$t('taskCampaign.step.general')" :before-change="toStep2" icon="ti-settings"  >
            <form data-vv-scope="step-01">
            <div class="row">
              <!-- Titulo -->
              <div class="col-12 col-md-12">
                <div class="card">
                  <div class="card-body">
                    <div class="row">
                      <div class="col-md-11 mt-4 mb-3">
                        <label>{{$t('taskCampaign.title')}}</label>
                        <custom-input
                          v-model="task.title"
                          name="task.title"
                          type="text"
                          rootClassName="md-form mb-0"
                          inputClassName="md-form-control"
                          v-validate="{ required: true }"
                          :error="errors.first('task.title')">
                        </custom-input>
                      </div>
                      <div class="col-md-1 mt-4">
                        <label>{{$t('taskCampaign.status')}}</label>
                        <custom-switch
                          :listItems="[{text: '', value: 1}]"
                          v-model="task.status"
                          name="task.status"
                          type="checkbox"
                          stateClassName="p-primary"
                          :error="errors.first('task.status')">
                        </custom-switch>
                      </div>
                      <!-- Tipo Disparador -->
                      <div class="col-sm-12 form-group mb-3">
                        <label>{{ $t('taskCampaign.tipoDisparador')}}</label>
                        <v-select name="task.idTipoDisparador" label="nome" :clearable="false" :searchable="true"
                                  v-model="task.idTipoDisparador" :options="tipoDisparadorList"
                                  @search="tipoDisparadorFetchOptions" @input="onSelectTipoDisparador"
                                  v-validate="{ required: true }">
                          <template slot="option" slot-scope="option">
                            <div class="d-center">
                              {{ option.nome }}
                            </div>
                          </template>
                          <template slot="selected-option" slot-scope="option">
                            <div class="selected d-center">
                              {{ option.nome }}
                            </div>
                          </template>
                        </v-select>
                        <transition name="slide" mode="">
                          <small v-if="errors.first('task.idTipoDisparador')" class="royalc-error-field">{{ errors.first('task.idTipoDisparador') }}</small>
                        </transition>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Descrição -->
              <div class="col-12 col-md-12">
                <div class="card">
                  <div class="card-body">
                    <div class="row">
                      <div class="col-md-12 mt-4">
                        <label>{{$t('taskCampaign.description')}}</label>
                        <custom-textarea
                          v-model="task.description"
                          name="task.description"
                          type="text"
                          rootClassName="md-form mb-0"
                          inputClassName="md-form-control"
                          :rows="3"
                          v-validate="{ required: false }"
                          :error="errors.first('task.description')">
                        </custom-textarea>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Remetente -->
              <div class="col-12 col-md-4">
                <div class="card card-fullheight">
                  <div class="card-body">
                    <div class="row">
                      <div class="col-md-12 mt-2 mb-4">
                        <label>{{$t('mails.template.sender')}}</label>
                        <v-select label="email" name="task.sender" class="md-form-control"  :clearable="true" :searchable="true" v-model="task.sender"  :options="ccContactList" v-validate="{ required: true }">
                          <template slot="no-options">
                            {{ 'Nenhum resultado encontrado' }}
                          </template>
                          <template slot="option" slot-scope="option">
                            <div class="d-center">
                              {{ option.email }}
                            </div>
                          </template>
                          <template slot="selected-option" slot-scope="option">
                            <div class="selected d-center">
                              {{ option.email }}
                            </div>
                          </template>
                        </v-select>
                        <transition name="slide" mode="">
                          <small v-if="errors.first('task.sender')" class="royalc-error-field">{{ errors.first('task.sender') }}</small>
                        </transition>
                        <div class="font-11">
                        Não esqueça de preencher o CCO, se necessário receber a cópia dos e-mails.<br/>
                        </div>
                      </div>
                      <!-- Default Sender-->
                      <div class="col-md-12 mt-2 mb-4" v-show="task.sender && task.sender.id === 'vendedor'">
                        <label>{{$t('mails.template.defaultSender')}}</label>
                        <v-select label="email" name="task.defaultSender" class="md-form-control"  :clearable="true" :searchable="true" v-model="task.defaultSender"  :options="defaulSenderList" v-validate="{ required: (task.sender && task.sender.id === 'vendedor') }">
                          <template slot="no-options">
                            {{ 'Nenhum resultado encontrado' }}
                          </template>
                          <template slot="option" slot-scope="option">
                            <div class="d-center">
                              {{ option.email }}
                            </div>
                          </template>
                          <template slot="selected-option" slot-scope="option">
                            <div class="selected d-center">
                              {{ option.email }}
                            </div>
                          </template>
                        </v-select>
                        <transition name="slide" mode="">
                          <small v-if="errors.first('task.defaultSender')" class="royalc-error-field">{{ errors.first('task.defaultSender') }}</small>
                        </transition>
                        <div class="font-11">
                          Remetente utilizado quando Cliente:<br/>
                          - Não possuir nenhum vendedor vinculado;<br/>
                          - Vendedor vinculado for igual à "Alan" ou<br/>
                          - Vendedor vinculado estiver inativo.<br/>

                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Com cópia -->
              <div class="col-12 col-md-4">
                <div class="card card-fullheight">
                  <div class="card-body">
                    <div class="row">
                      <div class="col-md-12 mt-2 mb-4">
                        <label>{{$t('mails.template.cc')}}</label>
                        <multiselect
                          v-model="task.cc"
                          :multiple="true"
                          :max="50"
                          track-by="id"
                          label="name"
                          :allow-empty="true"
                          placeholder="Selecione uma ou mais opções"
                          :close-on-select="false"
                          :clear-on-select="true"
                          :custom-label="mailCustomLabel"
                          :options="ccContactList"
                          :show-labels="false">
                          <template slot="singleLabel" slot-scope="{ option }">
                            <strong>{{ option.email }}</strong>
                          </template>
                          <template slot="option" slot-scope="{ option }">
                            <strong>{{ option.email }}</strong>
                          </template>
                        </multiselect>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Com cópia oculta -->
              <div class="col-12 col-md-4">
                <div class="card card-fullheight">
                  <div class="card-body">
                    <div class="row">
                      <div class="col-md-12 mt-2 mb-4">
                        <label>{{$t('mails.template.cco')}}</label>
                        <multiselect
                          v-model="task.cco"
                          :multiple="true"
                          :max="50"
                          track-by="id"
                          label="name"
                          :allow-empty="true"
                          placeholder="Selecione uma ou mais opções"
                          :close-on-select="false"
                          :clear-on-select="true"
                          :custom-label="mailCustomLabel"
                          :options="ccContactList"
                          :show-labels="false">
                          <template slot="singleLabel" slot-scope="{ option }">
                            <strong>{{ option.email }}</strong>
                          </template>
                          <template slot="option" slot-scope="{ option }">
                            <strong>{{ option.email }}</strong>
                          </template>
                        </multiselect>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Frequência -->
              <div class="col-6 col-md-6">
                <div class="card card-fullheight">
                  <div class="card-body">
                    <h5 class="box-title mb-3">{{ $t('schedules.frequency') }}</h5>
                      <div class="row">
                        <div class="col-sm-3 col-12 form-group mb-3">
                          <label>Enviar a cada</label>
                          <input name="task.taskFrequency.period" class="md-form-control" v-model="task.taskFrequency.period"  type="number"  placeholder="1"
                                 v-validate="{ required: true, min_value: 1 }"
                                 :error="errors.first('task.taskFrequency.period')" >
                          <transition name="slide" mode="">
                            <small v-if="errors.first('task.taskFrequency.period')" class="royalc-error-field">{{ errors.first('task.taskFrequency.period') }}</small>
                          </transition>

                        </div>
                        <div class="col-sm-9 col-12 form-group mb-6">
                          <label>&nbsp;</label>
                          <v-select label="name"  :clearable="false" :searchable="false" v-model="task.taskFrequency.type"  :options="recurrenceList" @input="onSelectRecurrence"  >
                            <template slot="option" slot-scope="option">
                              <div class="d-center">
                                {{ option.name }}
                              </div>
                            </template>
                            <template slot="selected-option" slot-scope="option">
                              <div class="selected d-center">
                                {{ option.name }}
                              </div>
                            </template>
                          </v-select>
                        </div>
                        <div v-if="showWeekdayConfig"  class="col-sm-12 form-group mb-12">
                          <label>Repetir</label>
                          <multiple-toggle :options="weekDayList"></multiple-toggle>
                          <transition name="slide" mode="">
                            <small v-if="!validWeekDay" class="royalc-error-field">{{ 'Você deve selecionar pelo menos um dia da semana.' }}</small>
                          </transition>
                        </div>
                        <div v-if="showMonthdayConfig"  class="col-sm-12 form-group mb-12">
                          <label>No Dia</label>
                          <input name="task.taskFrequency.monthday" class="md-form-control" v-model="task.taskFrequency.monthday"  type="text"
                                 v-validate="{ required: true, min_value: 1, max_value: 31 }"
                                 :error="errors.first('task.taskFrequency.monthday')" >
                          <transition name="slide" mode="">
                            <small v-if="errors.first('task.taskFrequency.monthday')" class="royalc-error-field">{{ errors.first('task.taskFrequency.monthday') }}</small>
                          </transition>
                        </div>
                      </div>
                  </div>
                </div>
              </div>
            </div>
            </form>
          </tab-content>
          <tab-content :title="$t('taskCampaign.step.select recipients')"  :before-change="toStep3" icon="ti-user">
            <form data-vv-scope="step-02">
            <div class="row">
              <!-- Regras de seleção de contatos -->
              <div class="col-12 col-md-12">
                <div class="card">
                  <div class="card-body">
                    <div class="row">
                      <div class="col-md-12 mt-4 mb-5">
                        <label>{{$t('taskCampaign.select contact rules')}}</label>
                        <!-- Novo -->
                        <div class="row">
                          <div class="col-12 accordion no-padding">
                            <div v-for="(group, index) in variableGroupsList" v-bind:key="index">
                              <div class="accordion-header" :class="{ active: group.isActive }" v-on:click="groupToggle(group)">
                                {{ (group) ? group.nome : 'Geral' }}
                                <label  v-if="group && group.descricao" >
                                  <span><i class="ft-alert-circle"></i>
                                    <md-tooltip md-direction="right" v-html="group.descricao"></md-tooltip>
                                  </span>
                                </label>
                                <span class="pull-right">
                                    <i class="fa fa-plus" v-if="!group.isActive"></i>
                                    <i class="fa fa-minus" v-else></i>
                                </span>
                              </div>
                              <div class="accordion-content" v-show="group.isActive">
                                <div class="row">
                                  <div class="col-md-12 mt-4" v-for="item in getOptionsFiltersByGroup((group) ? group.id : null)" :key="item.id" >
                                    <label>{{$t('taskCampaign.fields.'+item.fieldA.nome)}}</label>
                                    <multiselect
                                      v-model="item.value"
                                      :id="item.fieldA.codigo"
                                      key="id"
                                      :multiple="true"
                                      :max="1000"
                                      track-by="nome"
                                      label="nome"
                                      :allow-empty="true"
                                      placeholder="Selecione um ou mais"
                                      :loading="isLoadingSearch"
                                      :close-on-select="false"
                                      :clear-on-select="true"
                                      :custom-label="customLabel"
                                      :options="item.options"
                                      :show-labels="false"
                                      :internal-search="false"
                                      @search-change="asyncFind"
                                      @input="onInput"
                                    >
                                      <template slot="noOptions">
                                        Digite para pesquisar
                                      </template>
                                      <template slot="noResult">
                                        Ops! Nenhum resultado encontrado
                                      </template>
                                    </multiselect>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <!-- FIM  Novo -->
                        <div class="row">
                          <div class="col-md-12 no-padding mt-4">
                            <label>{{$t('taskCampaign.when')}}</label>
                            <div class="col-md-12 no-margim no-padding" v-for="(item, index) in task.filters" :key="item.id" >
                              <div class="row" v-if="isFieldFilter(item)">
                                <div class="col-md-11 pl-0">
                                  <field-filter  v-model="task.filters[index]" :fields="getFieldFilterVariableList" ></field-filter>
                                </div>
                                <div class="col-md-1 pl-0 pr-0">
                                  <a class="btn btn-link btn-sm" @click="removeFilter(index)" tabindex="">
                                    <i class="text-primary  ti-trash font-20"></i>
                                  </a>
                                  <a class="btn btn-link btn-sm" @click="addFilter(index)" tabindex="">
                                    <i class="text-primary fa fa-plus-circle font-20"></i>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>

                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            </form>
          </tab-content>
          <tab-content :title="$t('taskCampaign.step.message')" :before-change="toStep4" icon="ti-email"  >
            <form data-vv-scope="step-03">
              <div class="row">
                <!-- Origens e destinos -->
                <div class="col-12 col-sm-12 col-md-12">
                  <div class="card card-fullheight">
                    <div class="card-body">
                      <h5 class="box-title mb-3">{{ $t('schedules.originsDestinations') }}</h5>
                      <origin-destination-bulk-form v-on:toggleVisibility="toggleVisiblity"  v-on:addOriginsDestinationsGroup="addOriginDestinationGroup"   :opened="OriginDestinationGroup.visible" :show-long-term="isSuperAdmin" :strategy-list="strategyList" :tier-list="tierList" v-model="OriginDestinationGroup" name="origin-destination-group-form" />
                      <div class="col-12 col-sm-12" v-show="!OriginDestinationGroup.visible">
                        <table class="table table-hover">
                          <thead>
                          <tr>
                            <th width="23%">
                              Origem
                            </th>
                            <th width="23%">
                              Destino
                            </th>
                            <th width="10%">
                              Tier
                            </th>
                            <th width="30%">
                              Estratégia
                            </th>
                            <th>
                              Opções
                            </th>
                            <th width="10%">
                              Validade (Dias)
                            </th>
                            <th width="4%" v-if="isSuperAdmin">
                              <i :title="'Deseja enviar opções com o Tipo de Frete Long Term?'" class="fa fa-question-circle"></i>
                              Long Term
                              <label class="ui-switch no-margin small switch-solid switch-warning">
                                <input type="checkbox" @input="toggleLongTermTransbordoAll()" :checked="longTermAllSelected"><span></span>
                              </label>
                            </th>
                            <th width="4%">
                              <i :title="'Deseja enviar opções com o serviço marítimo com transbordo?'" class="fa fa-question-circle"></i><br/>
                              Aceita Transbordo
                              <label class="ui-switch no-margin small switch-solid switch-warning">
                                <input type="checkbox" @input="toggleAceitaTransbordoAll()" :checked="aceitaTransbordoAllSelected"><span></span>
                              </label>
                            </th>
                            <th width="4%">
                              <i :title="'Deixe esse campo inativo, quando cliente trabalha, mas não deseja receber tarifário para essa origem x destino'" class="fa fa-question-circle"></i>
                              Enviar opção
                              <label class="ui-switch no-margin small switch-solid switch-warning">
                                <input type="checkbox" @input="toggleReceberAll()" :checked="receberAllSelected"><span></span>
                              </label>
                            </th>
                            <th style="min-width:110px;">
                            </th>
                          </tr>
                          </thead>
                          <tbody>
                          <origin-destination-form v-for="(originDestination,index) in task.originsDestinations"
                                                   :name="'originDestination_' + index"
                                                   :row-index="index" :value="originDestination"
                                                   :ref="'originDestination_' + index"
                                                   :add-origin-destination="addOriginDestination"
                                                   :remove-origin-destination="removeOriginDestination"
                                                   :show-long-term="isSuperAdmin"
                                                   :strategy-list="strategyList"
                                                   :origin-destination-fetch-options="originDestinationFetchOptions"
                                                   :origin-destination-list="originsDestinationsList"
                                                   :tierList="tierList"
                                                   v-bind:key="index"
                          >
                          </origin-destination-form>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- Material  -->
                <div class="col-md-6 col-12">
                  <div class="card card-fullheight">
                    <div class="card-body">
                      <h5 class="box-title mb-3">{{ $t('schedules.commoditie') }}</h5>
                      <advanced-multiple-select  :value="task.commodities" :options="commoditiesList" :no-options="'Buscar Material'"  labelField="nome"  :tableFields="commoditiesFields" v-bind:fetchOptions="commoditiesFetchOptions"></advanced-multiple-select>
                    </div>
                  </div>
                </div>

                <!-- Tipo de Equipamento -->
                <div class="col-md-6 col-12">
                  <div class="card card-fullheight">
                    <div class="card-body">
                      <h5 class="box-title mb-3">{{ $t('schedules.equipment') }}</h5>
                      <advanced-multiple-select  :value="task.equipments"  :options="equipmentList" labelField="description"  :no-options="'Buscar Equipamento'"   :tableFields="equipmentFields" v-bind:fetchOptions="equipmentFetchOptions"></advanced-multiple-select>
                    </div>
                  </div>
                </div>

                <!-- Armador  -->
                <div class="col-12">
                  <div class="card card-fullheight">
                    <div class="card-body">
                      <div class="row">
                        <h5 class="box-title mb-3 col-9">{{ $t('schedules.shipowners') }}</h5>
                        <custom-switch
                          :listItems="[{text: 'Exibir Armador', value: 1}]"
                          v-model="task.showShipowner"
                          name="task.showShipowner"
                          type="checkbox"
                          :value="task.showShipowner"
                          stateClassName="p-primary"
                          rootClassName="col-sm-3 mb-3 mt-3"
                          :disabled="!isShowShipownerEnabled"
                          :error="errors.first('task.showShipowner')">
                        </custom-switch>
                      </div>

                      <div class="row">
                        <div class="col-md-6">
                          <h6 class="mt-3">{{ $t('schedules.shipowners_accept') }}</h6>
                          <advanced-multiple-select :enableStatus="1" :value="task.shipowners" :options="transportCompanyList" labelField="nome"   :emptyMessage="$t('all')" :no-options="'Buscar Armador'" :tableFields="transportCompanyFields" v-bind:fetchOptions="transportCompanyFetchOptions"></advanced-multiple-select>
                        </div>
                        <div class="col-md-6">
                          <h6 class=" mt-3">{{ $t('schedules.shipowners_refuse') }}</h6>
                          <advanced-multiple-select :enableStatus="2" :value="task.shipowners" :options="transportCompanyList" labelField="nome"  :emptyMessage="$t('none')" :no-options="'Buscar Armador'" :tableFields="transportCompanyFields" v-bind:fetchOptions="transportCompanyFetchOptions"></advanced-multiple-select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- Formato -->
                <div class="col-12" v-if="isSuperAdmin">
                  <div class="card card-fullheight">
                    <div class="card-body">
                      <div class="row">
                        <div class="col-12">
                          <label for="attach-format">Formato do anexo</label>
                          <v-select id="attach-format"
                                    label="name"
                                    :clearable="false"
                                    :searchable="false"
                                    v-model="task.attachFormat"
                                    :options="attachFormatList"
                                    @input="onSelectAttachFormat">
                            <template slot="option" slot-scope="option">
                              <div class="d-center">
                                {{ option.name }}
                              </div>
                            </template>
                            <template slot="selected-option" slot-scope="option">
                              <div class="selected d-center">
                                {{ option.name }}
                              </div>
                            </template>
                          </v-select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- Restrições de envio -->
                <div class="col-12">
                  <div class="card card-fullheight">
                    <div class="card-body">
                      <h5 class="box-title mb-3">Restrições de envio</h5>
                      <div>
                        <custom-switch
                          :listItems="[{text: 'Não enviar se cliente possuir um ou mais processos aguardando embarque', value: 1}]"
                          v-model="task.notSendWithOpenedProcesses"
                          name="task.notSendWithOpenedProcesses"
                          type="checkbox"
                          stateClassName="p-primary"
                          rootClassName="text-left col-12 mt-2 mb-6"
                          :error="errors.first('task.notSendWithOpenedProcesses')">
                          <template slot="helper">
                            <i class="ft-help-circle"></i><md-tooltip md-direction="right">
                            Regra:<br/>
                            - Processos de agenciamento de carga<br/>
                            - Com data de embarque não informada<br/>
                            - Tipo diferente de VIP<br/>
                            - Status do processo Diferente de JUNK e Em cobrança Judicial<br/>
                            - Situação diferente de Finalizado e Cancelado<br/>
                          </md-tooltip>
                          </template>
                        </custom-switch>
                      </div>
                      <div>
                        <custom-switch
                          :listItems="[{text: 'Não enviar se cliente possuir uma ou mais propostas em aberto', value: 1}]"
                          v-model="task.notSendWithOpenedProposals"
                          name="task.notSendWithOpenedProposals"
                          type="checkbox"
                          stateClassName="p-primary"
                          rootClassName="text-left col-12 mt-2 mb-6"
                          :error="errors.first('task.notSendWithOpenedProposals')">
                          <template slot="helper">
                            <i class="ft-help-circle"></i><md-tooltip md-direction="right">
                            Regra:<br/>
                            - Propostas com situação diferente de  1: Aguardando aprovação, 4: Não enviada e 6: Enviada<br/>
                            - Com Data da proposta aberta a 10 dias ou menos<br/>
                          </md-tooltip>
                          </template>
                        </custom-switch>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </tab-content>
          <tab-content :title="$t('taskCampaign.step.confirm')"  icon="ti-check">
            <div class="row">
              <div class="col-md-12">
                <div class="card">
                  <div class="card-body">
                    <div class="row">
                      <div class="col-md-6 mt-4">
                        <label>{{$t('taskCampaign.title')}}</label>
                        <p class="text-body ">{{ task.title }}</p>
                      </div>
                      <div class="col-md-6 mt-4">
                        <label>{{$t('taskCampaign.description')}}</label>
                        <p class="text-body ">{{ task.description }}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Contatos selecionados -->
              <div class="col-12 col-md-12">
                <div class="card">
                  <div class="card-body">
                    <custom-vue-table
                      label="taskCampaign.select contact rules"
                      ref="contactsTable"
                      http-method="post"
                      :api-url="loadContactEndpoint"
                      :fields="fieldsTable"
                      :filter-fields="previewContactsfilterFields"
                      :sort-order="sortOrder"
                      totalLabel="contatos"
                      :append-params="activeFilters"
                      :row-class="rowClass"
                      track-by="Contato_id"
                    >
                      <template slot="actions" slot-scope="props">
                        <div class="custom-actions">
                          <span v-if="props.rowData.hasOwnProperty('Restricao_Envio') && props.rowData.Restricao_Envio">
                            Restrição de envio
                          </span>
                        </div>
                      </template>
                    </custom-vue-table>
                  </div>
                </div>
              </div>
              <!-- Contatos duplicados -->
              <div class="col-12 col-md-12">
                <div class="card">
                  <div class="card-body">
                    <custom-vue-table
                      label="taskCampaign.select duplicated contact rules"
                      :description="'Lista de contatos duplicados, ou seja, que receberão o e-mail mais de uma vez.<br/>Para cancelar o envio, basta selecionar a opção Cancelar Envio '"
                      ref="contactsDuplicatedTable"
                      http-method="post"
                      :api-url="loadDuplicatedContactEndpoint"
                      :fields="fieldsDuplicatedTable"
                      :filter-fields="previewDuplicatedContactsfilterFields"
                      :sort-order="sortOrderDuplicates"
                      totalLabel="contatos"
                      :append-params="activeFilters"
                      :row-class="rowClass"
                      track-by="Contato_id"
                    >
                      <template slot="selected" slot-scope="props">
                        <div class="custom-actions">
                          <input type="checkbox" v-model="task.cancelSendList" :value="{ Contato_id: props.rowData.Contato_id }">
                        </div>
                      </template>
                      <template slot="actions" slot-scope="props">
                        <div class="custom-actions">
                          <span v-if="props.rowData.hasOwnProperty('Restricao_Envio') && props.rowData.Restricao_Envio">
                            Restrição de envio
                          </span>
                        </div>
                      </template>
                    </custom-vue-table>
                  </div>
                </div>
              </div>

            </div>
          </tab-content>
          <!-- Action Buttons -->
          <template slot="footer" slot-scope="props">
            <div class="wizard-footer-left">
            </div>
            <div class="wizard-footer-right">
              <button  v-if="props.activeTabIndex > 0" v-on:click="props.prevTab()"  class="btn  btn-outline-light">
                <i class="text-primary fa fa-angle-double-left font-20"></i> Voltar
              </button>
              <button v-if="!props.isLastStep" v-on:click="props.nextTab()" class="btn  btn-outline-light" href="javascript:;">
                Avançar <i class="text-primary fa fa-angle-double-right font-20"></i>
              </button>
              <button v-else v-on:click="onSave()" class="btn  btn-outline-light">
                {{props.isLastStep ? 'Salvar' : 'Próximo'}}
              </button>
            </div>
          </template>
        </form-wizard>
      </div>

    </div>
  </div>
</template>

<script>
import Vue from 'vue'
// Modal
import VModal from 'vue-js-modal'
import 'vue-js-modal/dist/styles.css'

// Validator
import VeeValidate from 'vee-validate'
import { locale } from '@/utils/validator'

import dayjs from 'dayjs'

import CustomVueTable from '@/components/Table/CustomVueTable'

// Loading
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

// Components
import OriginDestinationForm from '../OriginDestinationForm'
import AdvancedMultipleSelect from '@/components/Forms/AdvancedMultipleSelect.vue'
import OriginDestinationBulkForm from '@/views/Schedules/TaskBulk/OriginDestinationBulkForm'

// eslint-disable-next-line
import { util } from '@/utils/utils.js'

// eslint-disable-next-line
import '@/assets/vendor/reflow-table/js/reflow-table.js'
import '@/assets/vendor/reflow-table/css/reflow-table.css'

// eslint-disable-next-line
import '@/assets/vendor/bootstrap-datepicker/dist/js/bootstrap-datepicker.min.js'
// eslint-disable-next-line
import '@/assets/vendor/bootstrap-datepicker/dist/locales/bootstrap-datepicker.pt-BR.min.js'
import '@/assets/vendor/bootstrap-datepicker/dist/css/bootstrap-datepicker3.min.css'

// Form
import CustomInput from '@/components/Forms/CustomInput.vue'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'
import CustomTextarea from '@/components/Forms/CustomTextarea.vue'
import Portuguese from '@/i18n/flatpickr/pt.js'

import MultipleToggle from '@/components/Forms/MultipleToggle.vue'
import CustomSwitch from '@/components/Forms/CustomSwitch.vue'

import Multiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.min.css'

import { FormWizard, TabContent } from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import FieldFilter from './FieldFilter'

// Services
import UserService from '@/services/UserService'
import OriginDestinationService from '@/services/OriginDestinationService'
import TaskService from '@/services/TaskService'
import CommoditieService from '@/services/CommoditieService'
import MailService from '@/services/MailService'
import TaskCampaignTypeService from '@/services/TaskCampaignTypeService'
import TierService from '@/services/TierService'
import TransportCompanyService from '@/services/TransportCompanyService'
import EquipmentService from '@/services/EquipmentService'

VeeValidate.Validator.localize({ 'pt_BR': locale })
Vue.use(VeeValidate, { locale: 'pt_BR', fieldsBagName: 'formFields' })
Vue.use(VModal, { dynamic: true, dynamicDefaults: { clickToClose: false } })

export default {
  name: 'ProcessList',
  metaInfo () {
    return {
      titleTemplate: this.$i18n.t('schedules.title') + ' ' + this.$i18n.t('sidebar.tariffs') + ' - %s',
      breadcrumbs: [
        // { text: i18n.t('kpi.title'), disabled: false, href: 'KpiIndex' },

      ]
    }
  },
  data () {
    return {
      delay: 500,
      OriginDestinationGroup: {
        tier: null,
        status: 1,
        long_term: false,
        aceita_transbordo: true,
        options_per_origin_destination: null,
        approach: null,
        minimum_validity: null,
        origin: null,
        destination: null,
        visible: false
      },
      tipoDisparadorList: [],
      previewContactsfilterFields: [
        {
          name: '"Cliente_Nome"',
          title: 'Cliente',
          type: 'text',
          value: null
        },
        {
          name: '"Contato_Nome"',
          title: 'Nome',
          type: 'text',
          value: null
        },
        {
          name: '"Contato_email"',
          title: 'E-mail',
          type: 'text',
          value: null
        }
      ],
      previewDuplicatedContactsfilterFields: [
        {
          name: '"Cliente_Nome"',
          title: 'Cliente',
          type: 'text',
          value: null
        },
        {
          name: '"Contato_Nome"',
          title: 'Nome',
          type: 'text',
          value: null
        },
        {
          name: '"Contato_email"',
          title: 'E-mail',
          type: 'text',
          value: null
        }
      ],
      commoditiesFields: [
        {
          name: 'id',
          title: '#',
          sortField: 'id',
          formatter: (value) => this.$util.formatEmpty(value)
        },
        {
          name: 'nome',
          title: this.$i18n.t('origin_destination.name'),
          sortField: 'name',
          width: '90%',
          formatter: (value) => this.$util.formatEmpty(value)
        }
      ],
      transportCompanyFields: [
        {
          name: 'id',
          title: '#',
          sortField: 'id',
          formatter: (value) => this.$util.formatEmpty(value)
        },
        {
          name: 'nome',
          title: this.$i18n.t('transport_company.name'),
          sortField: 'nome',
          width: '90%',
          formatter: (value) => this.$util.formatEmpty(value)
        }
      ],
      equipmentFields: [
        {
          name: 'description',
          title: this.$i18n.t('equipment.description'),
          sortField: 'description',

          formatter: (value) => this.$util.formatEmpty(value)
        }
      ],
      dayParse: 'DD/MM/YYYY',
      // Begin Customer and contacts
      modalIdTemplate: null,
      activeCustomer: null,
      ccContactList: [],
      // End Customer and contacts
      configs: {
        timePicker: {
          wrap: true,
          enableTime: true,
          enableSeconds: false,
          noCalendar: true,
          time_24hr: true
        },
        datePicker: {
          wrap: true,
          enableTime: false,
          dateFormat: 'd/m/Y',
          minDate: 'today',
          locale: Portuguese // locale for this instance only
        }
      },
      loadContactEndpoint: process.env.VUE_APP_APIENDPOINT + 'v2/mail/recipients',
      loadDuplicatedContactEndpoint: process.env.VUE_APP_APIENDPOINT + 'v2/mail/recipients-duplicated',
      sortOrder: [
        { field: 'Cliente_Nome', direction: 'asc' },
        { field: 'Contato_email', direction: 'asc' }
      ],
      sortOrderDuplicates: [
        { field: 'Contato_email', direction: 'asc' },
        { field: 'Cliente_Nome', direction: 'asc' }
      ],
      // End Table
      isLoading: true,
      isLoadingSearch: false,
      fullPage: true,
      processEndpoint: process.env.VUE_APP_APIENDPOINT + 'v2/task',
      // List of options to select on select input
      variableList: [],
      variableGroupsList: [],
      employeeList: [],
      originsDestinationsList: [],
      commoditiesList: [],
      transportCompanyList: [],
      weekDayList: [
        {
          'id': 1,
          'name': 'D',
          'title': 'Domingo',
          'active': false
        },
        {
          'id': 2,
          'name': 'S',
          'title': 'Segunda-feira',
          'active': false
        },
        {
          'id': 3,
          'name': 'T',
          'title': 'Terça-feira',
          'active': false
        },
        {
          'id': 4,
          'name': 'Q',
          'title': 'Quarta-feira',
          'active': false
        },
        {
          'id': 5,
          'name': 'Q',
          'title': 'Quinta-feira',
          'active': false
        },
        {
          'id': 6,
          'name': 'S',
          'title': 'Sexta-feira',
          'active': false
        },
        {
          'id': 7,
          'name': 'S',
          'title': 'Sábado',
          'active': false
        }
      ],
      equipmentList: [],
      tierList: [],
      strategyList: [],
      currencyList: [],
      recurrenceList: [],
      expiresTypeList: [
        { id: 1, name: 'never' },
        { id: 2, name: 'at' },
        { id: 3, name: 'in' }
      ],
      templateList: [],
      attachFormatList: [
        {
          id: 1,
          name: 'PDF'
        },
        {
          id: 2,
          name: 'Planilha'
        }
      ],
      //  Selected Values (Form)
      task: {
        idTipoDisparador: null,
        notSendWithOpenedProcesses: null,
        notSendWithOpenedProposals: null,
        showShipowner: null,
        shipowners: [],
        cc: [],
        cco: [],
        sender: [],
        cancelSendList: [],
        commodities: [],
        title: null,
        description: null,
        status: 1,
        filters: [],
        taskFrequency: {
          period: 1,
          beginTime: null,
          type: null,
          weekdays: [],
          expiresIn: null,
          expiresAt: null,
          expiresType: { id: 1, name: 'never' }
        },
        originsDestinations: [{
          long_term: 2,
          aceita_transbordo: 1,
          status: 1,
          origin: null,
          destination: null,
          tier: null,
          approach: null,
          options_per_origin_destination: null,
          minimum_validity: null
        }],
        equipments: [],
        attachFormat: {
          id: 1,
          name: 'PDF'
        }
      }
    }
  },
  components: {
    FieldFilter,
    CustomInput,
    Loading,
    MultipleToggle,
    CustomTextarea,
    vSelect,
    CustomSwitch,
    FormWizard,
    TabContent,
    Multiselect,
    CustomVueTable,
    OriginDestinationForm,
    OriginDestinationBulkForm,
    AdvancedMultipleSelect
  },
  // Apply filters to local filter
  beforeMount () {

  },
  mounted () {

  },
  created () {
    let _this = this
    _this.isLoading = true
    // Load basilares data
    TaskService.getTaskRecurrenceList().then(res => {
      _this.recurrenceList = res.data.data.filter(function (item) {
        return item.id !== 3
      })
      // Set Default
      _this.task.taskFrequency.type = _this.recurrenceList[1]
      TaskCampaignTypeService.getTaskCampaignTypeList().then(res => {
        _this.tipoDisparadorList = res.data.data
        _this.task.idTipoDisparador = _this.tipoDisparadorList[1]
        MailService.getTemplates().then(res => {
          _this.templateList = res.data.data
          MailService.getContacts({}, 1, 1000).then(res => {
            _this.ccContactList = res.data.data
            MailService.getVariables().then(res => {
              _this.variableList = res.data.data
              _this.variableGroupsList = res.data.groups
              TierService.getTiers().then(res => {
                _this.tierList = res.data.data
                _this.strategyList = res.data.strategy

                let defaultFilter = {
                  'per_page': 33
                }

                CommoditieService.getCommodities(defaultFilter).then(res => {
                  _this.commoditiesList = res.data.data

                  // Set default selected (FAK)
                  res.data.data[0].status = 1

                  _this.task.commodities.push(res.data.data[0])

                  TransportCompanyService.getTransportCompanies().then(res => {
                    _this.transportCompanyList = res.data.data
                    EquipmentService.getEquipments().then(res => {
                      _this.equipmentList = res.data.data
                      // Remove on Dev
                      for (let index in _this.variableList) {
                        let fieldA = _this.variableList[index]

                        if (fieldA.tipo === 4 || fieldA.tipo === 5 || fieldA.tipo === 6 || fieldA.tipo === 8) {
                          let filter = {}
                          filter.fieldA = fieldA
                          filter.value = []
                          filter.options = []
                          _this.task.filters.push(filter)
                        }
                      }
                      // END Remove on Dev

                      let taskId = this.$route.params.id_schedule | this.$route.query.id_schedule

                      if (taskId) {
                        TaskService.getTaskTariffBulk(taskId).then(res => {
                          // title
                          _this.task.title = res.data.title

                          _this.task.notSendWithOpenedProcesses = res.data.notSendWithOpenedProcesses
                          _this.task.notSendWithOpenedProposals = res.data.notSendWithOpenedProposals

                          _this.task.contacts = res.data.contacts
                          _this.task.cancelSendList = res.data.cancelSendList

                          // Description
                          _this.task.description = res.data.description

                          _this.task.cc = res.data.cc
                          _this.task.cco = res.data.cco
                          _this.task.sender = res.data.sender
                          _this.task.defaultSender = res.data.defaultSender

                          // Status
                          if (res.data.status === 1) {
                            _this.task.status = 1
                          } else {
                            _this.task.status = null
                          }

                          // Frequency
                          if (res.data.taskFrequency.beginTime) {
                            _this.task.taskFrequency.beginTime = dayjs(res.data.taskFrequency.beginTime).format('HH:mm')
                          }
                          _this.task.taskFrequency.period = res.data.taskFrequency.period
                          _this.task.taskFrequency.type = _this.recurrenceList.find((x) => {
                            return x.id === res.data.taskFrequency.type
                          })

                          _this.task.taskFrequency.expiresType = res.data.taskFrequency.expiresType

                          if (res.data.taskFrequency.expiresAt) {
                            _this.task.taskFrequency.expiresAt = dayjs(res.data.taskFrequency.expiresAt).format(_this.dayParse)
                          }

                          _this.task.taskFrequency.expiresIn = res.data.taskFrequency.expiresIn

                          _this.task.taskFrequency.monthday = res.data.taskFrequency.monthday
                          if (_this.task.taskFrequency.type.id === 2) {
                            let selectedWeekDays = res.data.taskFrequency.weekdays.split(',')

                            for (var index in selectedWeekDays) {
                              let weekDay = parseInt(selectedWeekDays[index])

                              for (var j in _this.weekDayList) {
                                let item = this.weekDayList[j]
                                if (item.id === weekDay) {
                                  item.active = true
                                  this.$set(this.weekDayList, j, item)
                                  break
                                }
                              }
                            }
                          }

                          // Origins / Destinations
                          _this.task.originsDestinations = res.data.originsDestinations

                          // CC
                          _this.task.cc = res.data.cc
                          // Filters

                          // Options filters
                          for (let filterIndex in res.data.filters) {
                            let filter = res.data.filters[filterIndex]
                            if (filter.fieldA.tipo === 4 || filter.fieldA.tipo === 5 || filter.fieldA.tipo === 6 || filter.fieldA.tipo === 8) {
                              let optionFilter = _this.task.filters.find(function (item) {
                                return item.fieldA.codigo === filter.fieldA.codigo
                              })
                              optionFilter.value = filter.value
                            }
                          }

                          // Field Filters
                          for (let filterIndex in res.data.filters) {
                            let filter = res.data.filters[filterIndex]
                            if (filter.value && filter.value[0].value !== null) {
                              filter.value = filter.value[0].value
                            }

                            if (filter.fieldA.tipo !== 4 && filter.fieldA.tipo !== 5 && filter.fieldA.tipo !== 6 && filter.fieldA.tipo !== 8) {
                              _this.task.filters.push(filter)
                            }
                          }

                          // If any filter by field add a empty
                          if (_this.task.filters.length === _this.getOptionsFilters.length) {
                            _this.addFilter(_this.task.filters.length)
                          }
                          _this.isLoading = false
                        }).catch(() => {
                          _this.isLoading = false
                        })
                      } else {
                        _this.addFilter(_this.task.filters.length)
                        _this.isLoading = false
                      }
                    })
                  })
                })
              })
            })
          })
        })
      })
    })
  },
  computed: {
    aceitaTransbordoAllSelected () {
      let total = this.task.originsDestinations.length
      let selected = this.task.originsDestinations.filter((originDestination) => {
        return originDestination.aceita_transbordo === 1
      }).length

      if (total === selected) {
        return true
      }

      return false
    },
    longTermAllSelected () {
      let total = this.task.originsDestinations.length
      let selected = this.task.originsDestinations.filter((originDestination) => {
        return originDestination.long_term === 1
      }).length

      if (total === selected) {
        return true
      }

      return false
    },
    receberAllSelected () {
      let total = this.task.originsDestinations.length
      let selected = this.task.originsDestinations.filter((originDestination) => {
        return originDestination.status === 1
      }).length

      if (total === selected) {
        return true
      }

      return false
    },
    validWeekDay () {
      if (this.showWeekdayConfig) {
        let selected = this.weekDayList.filter((el) => {
          return el.active === true
        })

        return (selected.length > 0)
      }

      return true
    },
    defaulSenderList () {
      let ccList = this.ccContactList.filter((el) => {
        return el.id !== 'vendedor'
      })

      return ccList
    },
    activeFilters () {
      return {
        filters: this.getActiveFilters(false),
        tipo_disparador: (this.task.idTipoDisparador) ? this.task.idTipoDisparador.id : null,
        contacts: this.task.contacts,
        verificar_cadencia: (this.task.idTipoDisparador) ? this.task.idTipoDisparador.verificar_cadencia : true
      }
    },
    fieldsDuplicatedTable () {
      return [
        {
          name: 'selected',
          title: 'Cancelar Envio',
          width: '85px',
          dataClass: 'text-center',
          titleClass: 'text-center'
        },
        {
          name: 'Cliente_Nome',
          title: this.$i18n.t('form.taskSend.customer_name'),
          sortField: 'Cliente_Nome',
          formatter: (value) => this.$util.formatEmpty(value)
        },
        {
          name: 'Contato_Nome',
          title: this.$i18n.t('form.taskSend.contact_name'),
          sortField: 'Contato_Nome',
          formatter: (value) => this.$util.formatEmpty(value)
        },
        {
          name: 'Contato_email',
          title: this.$i18n.t('form.taskSend.contact_email'),
          sortField: 'Contato_email',
          formatter: (value) => this.$util.formatEmpty(value)
        },
        {
          name: 'actions',
          title: 'Ações',
          dataClass: 'text-center wrap-actions',
          titleClass: 'text-center'
        }
      ]
    },
    fieldsTable () {
      return [
        {
          name: 'Cliente_Nome',
          title: this.$i18n.t('form.taskSend.customer_name'),
          sortField: 'Cliente_Nome',
          formatter: (value) => this.$util.formatEmpty(value)
        },
        {
          name: 'Contato_Nome',
          title: this.$i18n.t('form.taskSend.contact_name'),
          sortField: 'Contato_Nome',
          formatter: (value) => this.$util.formatEmpty(value)
        },
        {
          name: 'Contato_email',
          title: this.$i18n.t('form.taskSend.contact_email'),
          sortField: 'Contato_email',
          formatter: (value) => this.$util.formatEmpty(value)
        },
        {
          name: 'actions',
          title: 'Ações',
          dataClass: 'text-center wrap-actions',
          titleClass: 'text-center'
        }
      ]
    },
    getOptionsFilters () {
      let _this = this
      let optionsFilters = _this.task.filters.filter(function (item) {
        return item.fieldA.tipo === 4 || item.fieldA.tipo === 5 || item.fieldA.tipo === 6 || item.fieldA.tipo === 8
      })

      return optionsFilters
    },
    getFieldFilterVariableList () {
      let _this = this

      let fields = _this.variableList.filter((field) => {
        return field.tipo !== 4 && field.tipo !== 5 && field.tipo !== 6
      })

      // Ordenando campos por ordem alfabética considerando a tradução (Multi idioma)
      fields.sort(function (a, b) {
        let keyA = _this.applyTranslate(a.nome)
        let keyB = _this.applyTranslate(b.nome)

        // Compare the 2 values
        if (keyA < keyB) return -1
        if (keyA > keyB) return 1
        return 0
      })

      return fields
    },
    showBeginAtConfig () {
      if (this.task.taskFrequency.type && (this.task.taskFrequency.type.id === 1 || this.task.taskFrequency.type.id === 2 || this.task.taskFrequency.type.id === 4)) {
        return true
      }
      return false
    },
    showWeekdayConfig () {
      if (this.task.taskFrequency.type && this.task.taskFrequency.type.id === 2) {
        return true
      }
      return false
    },
    showMonthdayConfig () {
      if (this.task.taskFrequency.type && this.task.taskFrequency.type.id === 4) {
        return true
      }
      return false
    },
    showExpiresAtConfig () {
      if (this.task.taskFrequency.expiresType && this.task.taskFrequency.expiresType.id === 2) {
        return true
      }
      return false
    },
    showExpiresInConfig () {
      if (this.task.taskFrequency.expiresType && this.task.taskFrequency.expiresType.id === 3) {
        return true
      }
      return false
    },
    isSuperAdmin () {
      let userInfo = this.$store.getters.userInfo || null
      if (userInfo && userInfo.roles.indexOf('ROLE_SUPER_ADMIN') !== -1) {
        return true
      }
      return false
    },
    isShowShipownerEnabled () {
      let _this = this
      // Se nenhuma origem X destino preenchido sim
      if (_this.task.originsDestinations.length <= 0) {
        return true
      }

      // Se houver alguma origem X destino com a média sempre fica desabilitado
      for (let originDestinationIndex in _this.task.originsDestinations) {
        let originDestination = _this.task.originsDestinations[originDestinationIndex]

        if (originDestination.approach && originDestination.approach.id === 2) {
          _this.task.showShipowner = null
          return false
        }
      }

      return true
    }
  },
  watch: {
    user: function () {
      // CODIGO PARA REMOÇÂO DOS MENUS
    },
    isSuperAdmin: function () {
      // CODIGO PARA REMOÇÂO DOS MENUS
    }
  },
  methods: {
    toggleVisiblity (status) {
      this.OriginDestinationGroup.visible = status
    },
    addOriginDestinationGroup (OriginDestinationGroupForm) {
      let _this = this
      for (let originId in OriginDestinationGroupForm.origin) {
        for (let destinationId in OriginDestinationGroupForm.destination) {
          let origin = OriginDestinationGroupForm.origin[originId]
          let destination = OriginDestinationGroupForm.destination[destinationId]

          if (origin.id === destination.id) {
            continue
          }

          // Adiciona Origem e Destino
          // Clone object to not change _this.task by reference
          let selectedOriginDestination = {
            'long_term': OriginDestinationGroupForm.long_term,
            'aceita_transbordo': OriginDestinationGroupForm.aceita_transbordo,
            'status': OriginDestinationGroupForm.status,
            'origin': origin,
            'destination': destination,
            'tier': OriginDestinationGroupForm.tier,
            'approach': OriginDestinationGroupForm.approach,
            'options_per_origin_destination': OriginDestinationGroupForm.options_per_origin_destination,
            'minimum_validity': OriginDestinationGroupForm.minimum_validity
          }

          let index = 0
          for (let originDestinationId in _this.task.originsDestinations) {
            let originDestination = _this.task.originsDestinations[originDestinationId]
            // Se não preenchida origem e destino remove e não conta no index
            if (!originDestination.origin && !originDestination.destination) {
              _this.task.originsDestinations.splice(index, 1)
              continue
            }

            if (
              originDestination.origin &&
              originDestination.origin.id === origin.id &&
              originDestination.destination &&
              originDestination.destination.id === destination.id
            ) {
              _this.task.originsDestinations.splice(index, 1)
              break
            }
            index++
          }

          _this.task.originsDestinations.splice(index, 0, selectedOriginDestination)

          _this.OriginDestinationGroup.visible = false
        }
      }

      // Clear group form

      this.OriginDestinationGroup.tier = null
      this.OriginDestinationGroup.status = 1
      this.OriginDestinationGroup.long_term = false
      this.OriginDestinationGroup.aceita_transbordo = true
      this.OriginDestinationGroup.options_per_origin_destination = true
      this.OriginDestinationGroup.approach = null
      this.OriginDestinationGroup.minimum_validity = null
      this.OriginDestinationGroup.origin = null
      this.OriginDestinationGroup.destination = null
      this.OriginDestinationGroup.visible = false
    },
    validOriginDestinations () {
      let _this = this
      return new Promise(function (resolve, reject) {
        let promises = []

        for (let originDestinationIndex in _this.task.originsDestinations) {
          let originDestination = _this.task.originsDestinations[originDestinationIndex]
          let component = _this.$refs['originDestination_' + originDestinationIndex]

          promises.push(component[0].validate(originDestination))
        }

        Promise.all(promises).then((result) => {
          resolve(result.indexOf(false) === -1)
        })
      })
    },
    onSelectAttachFormat (val) {
      this.task.attachFormat = val
    },
    addOriginDestination (index) {
      let _this = this
      // Clone object to not change _this.task by reference
      let selectedOriginDestination = {}
      selectedOriginDestination = JSON.parse(JSON.stringify(_this.task.originsDestinations[index]))
      // Added empty field filter on began
      selectedOriginDestination.origin = null
      // Adicionar após
      index++

      _this.task.originsDestinations.splice(index, 0, selectedOriginDestination)
    },
    removeOriginDestination (index) {
      let _this = this
      _this.task.originsDestinations.splice(index, 1)

      // Se for o último adiciona um vazio
      if (_this.task.originsDestinations.length === 0) {
        let originDestination = {}
        _this.task.originsDestinations.push(originDestination)
      }
    },
    groupToggle (group) {
      let _this = this
      group.isActive = !group.isActive

      if (!group.hasOwnProperty('preLoad')) {
        let fields = _this.getOptionsFiltersByGroup(group.id)

        for (let fieldIndex in fields) {
          let filter = fields[fieldIndex]

          if (filter.fieldA.tipo === 4 || filter.fieldA.tipo === 5 || filter.fieldA.tipo === 6 || filter.fieldA.tipo === 8) {
            _this.asyncFindWithoutTimeout(' ', filter.fieldA.codigo)
          }
        }
        // Disable pre load
        group.preLoad = true
      }
    },
    removeSequenciaTemplate (index) {
      this.task.templates.splice(index, 1)
    },
    addSequenciaTemplate () {
      this.task.templates.push(
        { idTemplate: null }
      )
    },
    rowClass (item, type) {
      let className = ''
      if (item.hasOwnProperty('Restricao_Envio') && item.Restricao_Envio) {
        className = 'inativo'
      }
      return className
    },
    getOptionsFiltersByGroup (groupId) {
      let _this = this
      let optionsFilters = _this.getOptionsFilters

      optionsFilters = optionsFilters.filter(function (item) {
        let currentGroupId = (item.fieldA.id_task_field_grupo) ? item.fieldA.id_task_field_grupo.id : null

        // Se igual  ao grupo
        if (groupId === currentGroupId) {
          return true
        }

        return false
      })

      return optionsFilters
    },
    applyTranslate (val) {
      // Se não informado retorna vazio
      if (!val) {
        return ''
      }

      let translated = this.$i18n.t('taskCampaign.fields.' + val)

      if (translated.includes('taskCampaign.fields.')) {
        return val
      }

      return translated
    },
    scrollToTop () {
      global.window.scrollTo(0, 0)
    },
    isIncludedContact: function (contact) {
      let _this = this
      let selected = _this.task.contacts.find((x) => {
        return x.id === contact.id
      })

      if (selected !== undefined) {
        return true
      }
      return false
    },
    // First Step "Avançar"
    toStep2 () {
      return new Promise((resolve, reject) => {
        let _this = this
        _this.$validator.validateAll('step-01').then((result) => {
          if (result && this.validWeekDay) {
            this.scrollToTop()
            resolve(result)
          }
          return reject(result)
        })
      })
    },
    toStep4 () {
      return new Promise((resolve, reject) => {
        let _this = this
        _this.$validator.validateAll('step-03').then((result) => {
          if (result) {
            _this.validOriginDestinations().then((isValidOriginsDestinations) => {
              if (isValidOriginsDestinations) {
                _this.scrollToTop()
                _this.$refs.contactsTable.refresh()
                _this.$refs.contactsDuplicatedTable.refresh()

                resolve(true)
              } else {
                _this.$util.scrollToFirstError()

                return reject(result)
              }
            })
          } else {
            _this.$util.scrollToFirstError()
            return reject(result)
          }
        })
      })
    },
    // Second Step "Avançar"
    toStep3 () {
      return new Promise((resolve, reject) => {
        let _this = this
        // Reset Edit
        _this.validateFieldFilters().then(res => {
          if (res) {
            this.scrollToTop()
            resolve(true)
          } else {
            resolve(false)
          }
        })
      })
    },
    validateFieldFilters () {
      let total = 0
      let valids = 0

      return new Promise((resolve, reject) => {
        let _this = this
        for (let i in _this.task.filters) {
          let filter = _this.task.filters[i]
          if (_this.isFieldFilter(filter)) {
            if (filter.fieldA.tipo === 3 && filter.value !== null) {
              total++
              this.$validator.verify(filter.value, 'date_format:dd/MM/yyyy').then(res => {
                if (!res.valid) {
                  filter.error = res.errors
                  _this.task.filters.splice(i, 1, filter)
                  resolve(false)
                }
                valids++
                if (valids === total) {
                  resolve(true)
                }
              })
            }
          }
        }

        if (total === 0) {
          resolve(true)
        }
      })
    },
    getActiveFilters (toSave) {
      let _this = this

      // Clone object to not change _this.task by reference
      let taskData = {}
      taskData = JSON.parse(JSON.stringify(_this.task))

      // Adjust filters
      let activeFilters = taskData.filters.filter(function (x) {
        return ((Array.isArray(x.value) && x.value.length > 0) && x.value !== null) || (!Array.isArray(x.value) && ((x.value !== null && x.value !== '') || x.fieldA.hasOwnProperty('id')))
      })

      for (let i in activeFilters) {
        let activeFilter = activeFilters[i]
        activeFilter.condition = (activeFilter.condition) ? activeFilter.condition.id : null
        activeFilter.operator = (activeFilter.operator) ? activeFilter.operator.id : null

        if (activeFilter.fieldA.tipo === 4 || activeFilter.fieldA.tipo === 5 || activeFilter.fieldA.tipo === 6 || activeFilter.fieldA.tipo === 8) {
          activeFilter.condition = 6
          activeFilter.operator = 1
        }

        activeFilter.sortOrder = i

        if (!Array.isArray(activeFilter.value) && (!activeFilter.fieldB || !activeFilter.fieldB.hasOwnProperty('id'))) {
          let value = []
          value.push({
            value: activeFilter.value
          })
          activeFilter.value = value
        }

        if (toSave) {
          activeFilter.fieldA = activeFilter.fieldA.id
          if (activeFilter.fieldB) {
            activeFilter.fieldB = activeFilter.fieldB.id
          }
        }

        if (Array.isArray(activeFilter.options) || activeFilter.options == null) {
          delete activeFilter.options
        }
      }

      taskData.filters = activeFilters

      return taskData.filters
    },
    addFilter (index) {
      // Adicionar após
      index++
      let _this = this
      // Added empty field filter on began
      let filter = {}
      filter.fieldA = {
        tipo: 1
      }
      filter.value = null
      filter.condition = null
      filter.options = []

      _this.task.filters.splice(index, 0, filter)
    },
    removeFilter (index) {
      let _this = this
      _this.task.filters.splice(index, 1)

      // Se for o último adiciona um vazio
      if (_this.getOptionsFilters.length === _this.task.filters.length) {
        let filter = {}
        filter.fieldA = {
          tipo: 1
        }
        filter.value = null
        filter.condition = null
        filter.options = []
        _this.task.filters.push(filter)
      }
    },
    isFieldFilter (filter) {
      return filter.fieldA.tipo !== 4 && filter.fieldA.tipo !== 5 && filter.fieldA.tipo !== 6 && filter.fieldA.tipo !== 8
    },
    // Download example file
    async handleDownloadExample () {
      this.isLoading = true
      let fileName = 'contatos.csv'
      let _this = this

      TaskService.getTaskCampaignFile().then(res => {
        const url = global.window.URL.createObjectURL(new Blob([res.data]))
        const link = global.document.createElement('a')
        link.href = url
        link.setAttribute('download', fileName)
        global.document.body.appendChild(link)
        link.click()
        _this.isLoading = false
      }).catch(() => {
        _this.isLoading = false
      })
    },
    // eslint-disable-next-line camelcase
    customLabel ({ id, nome }) {
      // eslint-disable-next-line camelcase
      return `${id} – ${nome}`
    },
    mailCustomLabel ({ email }) {
      // eslint-disable-next-line camelcase
      return `${email}`
    },
    limitText (count) {
      return `de ${count} opções`
    },
    onInput (query, fieldId) {
      let _this = this
      _this.isLoadingSearch = true
      _this.isLoadingSearch = false
    },
    asyncFindWithoutTimeout (query, fieldId) {
      let _this = this

      let filterField = _this.task.filters.find((x) => {
        return x.fieldA.codigo === fieldId
      })

      if (query) {
        _this.isLoadingSearch = true

        let filter = {
          searchTerm: query,
          field: fieldId
        }

        MailService.getVariableSearch(filter).then(res => {
          filterField.options = []
          filterField.options = res.data.data
          _this.isLoadingSearch = false
        })
      } else {
        filterField.options = []
      }
    },
    asyncFind (query, fieldId) {
      let _this = this
      window.clearTimeout(_this.timer)

      this.timer = window.setTimeout(function () {
        let filterField = _this.task.filters.find((x) => {
          return x.fieldA.codigo === fieldId
        })

        if (query) {
          _this.isLoadingSearch = true

          let filter = {
            searchTerm: query,
            field: fieldId
          }

          MailService.getVariableSearch(filter).then(res => {
            filterField.options = []
            filterField.options = res.data.data
            _this.isLoadingSearch = false
          })
        } else {
          filterField.options = []
        }
      }, _this.delay)
    },
    showNumber (number, thousandsSep, defaultValue) {
      return util.showNumber(number, thousandsSep, defaultValue)
    },
    showCurrency (number, thousandsSep, defaultValue) {
      return util.numberFormat(number, 2, thousandsSep, defaultValue)
    },
    onSelectTier (val) {
      this.task.tier = val
    },
    onSelectRecurrence (val) {
      this.task.taskFrequency.type = val
    },
    onSelectExpiresType (val) {
      if (val.id !== 2) {
        this.task.taskFrequency.expiresAt = null
      } else if (val.id !== 3) {
        this.task.taskFrequency.expiresIn = null
      }
    },
    onSelectStrategy (val) {
      if (val.id === 2) {
        this.task.showShipowner = null
      }

      this.task.strategy = val
    },
    mountLink (itemName, object) {
      return this.$router.resolve({ name: itemName, params: { id_schedule: object.id } }).href
    },
    setEmployee (value) {
      this.task.employee = value
    },
    onSelectTipoDisparador (val) {
      this.task.idTipoDisparador = val
    },
    /**
     * Triggered when the template search text changes.
     *
     * @param search  {String}   Current search text
     * @param loading {Function} Toggle loading class
     */
    tipoDisparadorFetchOptions (search, loading) {
      loading(true)
      let filters = {
        'searchTerm': search,
        'per_page': 1000
      }

      TaskCampaignTypeService.getTaskCampaignTypeList(filters).then(res => {
        this.hasData = res.data.hasData
        this.tipoDisparadorList = res.data.data
        loading(false)
      })
    },
    /**
     * Triggered when the customer search text changes.
     *
     * @param search  {String}   Current search text
     * @param loading {Function} Toggle loading class
     */
    employeeFetchOptions (search, loading) {
      loading(true)
      let filters = {
        'searchTerm': search
      }

      UserService.getUsers(filters).then(res => {
        this.hasData = res.data.hasData
        this.employeeList = res.data.data
        loading(false)
      })
    },
    /**
     * Triggered when the customer search text changes.
     *
     * @param search  {String}   Current search text
     * @param loading {Function} Toggle loading class
     */
    originDestinationFetchOptions (search, loading) {
      let _this = this
      window.clearTimeout(this.timer)
      this.timer = window.setTimeout(function () {
        loading(true)
        let filters = {
          'searchTerm': search
        }

        OriginDestinationService.getOriginsDestinations(filters).then(res => {
          _this.hasData = res.data.hasData
          _this.originsDestinationsList = res.data.data
          loading(false)
        })
      }, _this.delay)
    },
    /**
     * Triggered when the commoditie search text changes.
     *
     * @param search  {String}   Current search text
     * @param loading {Function} Toggle loading class
     */
    commoditiesFetchOptions (search, loading) {
      loading(true)
      let filters = {
        'searchTerm': search,
        'per_page': 1000
      }

      CommoditieService.getCommodities(filters).then(res => {
        this.hasData = res.data.hasData
        this.commoditiesList = res.data.data
        loading(false)
      })
    },
    /**
     * Triggered when the template search text changes.
     *
     * @param search  {String}   Current search text
     * @param loading {Function} Toggle loading class
     */
    templateFetchOptions (search, loading) {
      loading(true)
      let filters = {
        'searchTerm': search,
        'per_page': 1000
      }

      MailService.getTemplates(filters).then(res => {
        this.hasData = res.data.hasData
        this.templateList = res.data.data
        loading(false)
      })
    },
    /**
     * Triggered when the transport companies search text changes.
     *
     * @param search  {String}   Current search text
     * @param loading {Function} Toggle loading class
     */
    transportCompanyFetchOptions (search, loading) {
      loading(true)
      let filters = {
        'searchTerm': search
      }

      TransportCompanyService.getTransportCompanies(filters).then(res => {
        this.hasData = res.data.hasData
        this.transportCompanyList = res.data.data
        loading(false)
      })
    },
    /**
     * Triggered when the commoditie search text changes.
     *
     * @param search  {String}   Current search text
     * @param loading {Function} Toggle loading class
     */
    equipmentFetchOptions (search, loading) {
      loading(true)
      let filters = {
        'searchTerm': search,
        'per_page': 1000
      }

      EquipmentService.getEquipments(filters).then(res => {
        this.hasData = res.data.hasData
        this.equipmentList = res.data.data
        loading(false)
      })
    },
    onSave () {
      let _this = this

      _this.isLoading = true

      // Clone object to not change _this.task by reference
      let taskData = {}
      taskData = JSON.parse(JSON.stringify(_this.task))

      taskData.filters = _this.getActiveFilters(true)
      // End filters

      taskData.equipments = _this.task.equipments.map(x => ({ equipment: x.id, status: (x.status) }))
      taskData.commodities = _this.task.commodities.map(x => ({ commoditie: x.id, status: (x.status) }))
      taskData.shipowners = _this.task.shipowners.map(x => ({ shipowner: x.id, status: (x.status) }))

      taskData.taskFrequency.type = taskData.taskFrequency.type.id
      taskData.attachFormat = _this.task.attachFormat.id

      // Tipo Disparador
      taskData.idTipoDisparador = taskData.idTipoDisparador.id

      taskData.originsDestinations = _this.task.originsDestinations.map(x => ({
        origin: x.origin.id,
        destination: x.destination.id,
        tier: x.tier.id,
        approach: x.approach.id,
        optionsPerOriginDestination: x.options_per_origin_destination,
        minimumValidity: x.minimum_validity,
        status: (x.status !== 1) ? 2 : 1,
        long_term: (x.long_term !== 1) ? 2 : 1,
        aceita_transbordo: (x.aceita_transbordo !== 1) ? 2 : 1
      }))

      if (taskData.taskFrequency.expiresType.id === 2) {
        let dayParse = _this.dayParse
        taskData.taskFrequency.expiresAt = dayjs(taskData.taskFrequency.expiresAt, dayParse).isValid() ? (dayjs(taskData.taskFrequency.expiresAt, dayParse).hour(0).minute(0).second(0)).toISOString() : null
      }

      // Remove Expires Type
      delete taskData.taskFrequency.expiresType

      taskData.taskFrequency.weekdays = _this.weekDayList.filter(function (x) {
        return x.active === true
      }).map(x => (x.id)).join(',')

      // Status
      taskData.status = parseInt(taskData.status)

      if (taskData.status !== 1) {
        taskData.status = 2
      }

      // Format CancelSendList

      taskData.cancelSendList = taskData.cancelSendList.map(item => {
        let formated = {}
        formated.Contato_id = item.Contato_id

        return formated
      })

      let taskId = this.$route.params.id_schedule

      if (taskId) {
        TaskService.editTaskTariffBulk(taskId, taskData).then(response => {
          _this.$router.push({ name: 'SchedulesBulkIndex', params: { ignoreOnLeaveConfirm: '1' } })
          _this.isLoading = false
        }).catch(() => {
          _this.isLoading = false
        })
      } else {
        TaskService.newTaskTariffBulk(taskData).then(response => {
          _this.$router.push({ name: 'SchedulesBulkIndex', params: { ignoreOnLeaveConfirm: '1' } })
          _this.isLoading = false
        }).catch(() => {
          _this.isLoading = false
        })
      }
    },
    beforeOpen (event) {
    },
    beforeClose (event, a, b) {
      // event.cancel()
    }
  }
}
</script>

<style scoped>
  .swal2-popup {
    width: inherit !important;
  }

  .card-body {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  .md-form-control {
    height: 30px;
  }

  .accordion {
    border-bottom: 1px solid #ccc;
  }

  .accordion-header {
    border: 1px solid #ccc;
    border-bottom: 0;
    padding: 0.5rem;
    font-family: "Pluto Sans Medium";
  }

  .accordion-header:hover, .accordion-header.active {
    background-color: #877953;
    color: #f1f1f1;
  }

  .accordion-content {
    padding: 0.5rem;
    padding-bottom: 1rem;
    border: 1px solid #ccc;
    border-bottom: 0;
    border-top: 0;
  }

  .sortable {
    padding-top: 1rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid #ccc;
  }

  .sortable:first-child {
    border-top: 1px solid #ccc;
  }

  .sortable .sort-icon {
    position: absolute;
    top: 5px;
    right: 5px;
  }

  .sortable-icon {
    height: 20px;
    width: 20px;
    position: relative;
    top: 10px;
    right: 16px;
    text-align: center;
  }

  .sortable-remove {
    font-size: 0.7vw;
    bottom: 0;
    right: 0;
    /*display: none;*/
  }

  .sortable:hover .sortable-remove {
    display: block;
  }

  .md-tooltip {
    height:auto
  }
</style>
